<template>
  <div>
    <h2 class="h5 mb-6 add-baremetal__title">
      {{ $t('untranslated.bareMetal') }}
    </h2>

    <p>{{ $t('p1') }}</p>
    <p
      class="mb-6"
      v-html="$sanitizeHtml($t('p2'))"/>

    <CyWorkersCodeEditor :code="code"/>

    <div class="mt-6">
      <i18n path="checkDoc">
        <a
          :href="$docLinks.workers.baremetal"
          class="cy-link"
          target="_blank">
          {{ $t('linkText') }}
        </a>
      </i18n>
      <i18n path="workers.troubleshoot">
        <a
          :href="$docLinks.workers.troubleshooting"
          class="cy-link"
          target="_blank">
          {{ $t('workers.troubleshootLink') }}
        </a>
      </i18n>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CyWorkersCodeEditor from '@/components/workers//code-editor.vue'
import { generateBareMetalCode, generateBareMetalCodeOnPrem } from '@/utils/config/workers'

export default {
  name: 'CyWorkersAddBaremetalStepTwo',
  components: {
    CyWorkersCodeEditor,
  },
  props: {
    teamId: {
      type: String,
      required: true,
    },
    workerKey: {
      type: String,
      required: true,
    },
    schedulerHost: {
      type: String,
      default: '',
    },
    schedulerPort: {
      type: String,
      default: '',
    },
    schedulerPublicTSAKey: {
      type: String,
      default: '',
    },
    schedulerAPIAddress: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('organization/licence', [
      'isOnPrem',
    ]),
    code () {
      const { teamId, workerKey, schedulerHost, schedulerPort, schedulerPublicTSAKey, schedulerAPIAddress } = this

      return this.isOnPrem
        ? generateBareMetalCodeOnPrem({
          teamId,
          workerKey,
          schedulerHost,
          schedulerPort,
          schedulerPublicTSAKey,
          schedulerAPIAddress,
        })
        : generateBareMetalCode({ teamId, workerKey })
    },
  },
  i18n: {
    messages: {
      en: {
        checkDoc: 'Check the {0} to get more information. ',
        linkText: '@:untranslated.bareMetal workers configuration',
        p1: 'A debian-based operating system is required to run a Cycloid worker on @:untranslated.bareMetal or on-premises servers.',
        p2: 'Run the command below to deploy your worker. Note that if your worker has a dedicated volume, you can improve performance by adding the device name (eg. <span class="doc-tag">/dev/nvmen1</span>) in the <span class="doc-tag">VAR_LIB_DEVICE</span> variable.',
      },
      es: {
        checkDoc: 'Compruebe la {0} para obtener más información. ',
        linkText: 'configuración de los workers de @:untranslated.bareMetal',
        p1: 'Se requiere un sistema operativo basado en Debian para que ejecute un worker de Cycloid en servidores @:untranslated.bareMetal o en servidores on-premise.',
        p2: 'Ejecute el comando a continuación para desplegar su worker. Tenga en cuenta que si su worker tiene un dispositivo de volumen dedicado para mejor rendimiento, debe colocar el nombre del dispositivo (por ejemplo, <span class="doc-tag">/dev/nvmen1</span>) en la variable <span class="doc-tag">VAR_LIB_DEVICE</span>.',
      },
      fr: {
        checkDoc: `Vérifiez la {0} pour obtenir plus d'informations. `,
        linkText: 'configuration des workers @:untranslated.bareMetal',
        p1: `Un système d'exploitation basé sur Debian est requis pour exécuter un worker Cycloid sur des serveurs @:untranslated.bareMetal ou sur on-premise.`,
        p2: `Exécutez la commande ci-dessous pour déployer votre worker. Notez que si votre worker a un dispositif de volume dédié pour de meilleures performances, placez le nom du périphérique (par exemple, <span class="doc-tag">/dev/nvmen1</span>) dans la variable <span class="doc-tag">VAR_LIB_DEVICE</span>.`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.add-baremetal {
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-weight: $font-weight-bold;
  }
}

::v-deep .doc-tag {
  padding: 4px 8px;
  border-radius: 8px;
  background-color: get-color("grey", "light-2");
}
</style>
