<template>
  <div class="add-azure">
    <CyWorkersAddTitle icon-type="azure">
      {{ $t('untranslated.azure') }}
    </CyWorkersAddTitle>
    <p>{{ $t('paragraph.workersDeployed') }}</p>
    <ul class="add-azure__list">
      <li>{{ $t('paragraph.deployToAzure') }}</li>
      <li>{{ $t('paragraph.copyPaste') }}</li>
      <li>
        <i18n path="checkDoc">
          <a
            :href="$docLinks.workers.azure"
            class="cy-link"
            target="_blank">{{ $t('linkText') }}</a>
        </i18n>
      </li>

      <template v-if="isOnPrem">
        <li>{{ $t('paragraph.serverConfig') }}</li>
        <ul class="add-azure__list--nested">
          <li>{{ $t('untranslated.schedulerHost') }}</li>
          <li>{{ $t('untranslated.schedulerPublicTSAKey') }}</li>
          <li>{{ $t('untranslated.schedulerPort') }}</li>
        </ul>
        <li>{{ $t('paragraph.help') }}</li>
      </template>
    </ul>
    <CyWorkersSummary>
      <CyWorkersSummaryItem
        v-if="!isOnPrem"
        :value="orgCanonical"
        :text="$t('untranslated.customerTag')"
        with-copy/>

      <CyWorkersSummaryItem
        :value="teamId"
        :text="$t('untranslated.teamId')"
        with-copy/>

      <CyWorkersSummaryItem
        :value="workerKey"
        :text="$t('untranslated.workerKey')"
        with-copy/>

      <CyWorkersSummaryItem
        v-if="isOnPrem"
        :value="schedulerAPIAddress"
        :text="$t('untranslated.schedulerAPIAddress')"
        with-copy/>
    </CyWorkersSummary>

    <p class="mt-6 mb-0">
      <i18n path="workers.troubleshoot">
        <a
          :href="$docLinks.workers.troubleshooting"
          class="cy-link"
          target="_blank">{{ $t('workers.troubleshootLink') }}</a>
      </i18n>
    </p>
  </div>
</template>

<script>
import CyWorkersSummary from './summary'
import CyWorkersSummaryItem from './summary-item'
import CyWorkersAddTitle from './add-title'
import { mapGetters } from 'vuex'

export default {
  name: 'CyWorkersAddAzure',
  components: {
    CyWorkersSummary,
    CyWorkersSummaryItem,
    CyWorkersAddTitle,
  },
  props: {
    teamId: {
      type: String,
      required: true,
    },
    workerKey: {
      type: String,
      required: true,
    },
    schedulerAPIAddress: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('organization/licence', [
      'isOnPrem',
    ]),
  },
  i18n: {
    messages: {
      en: {
        checkDoc: 'Check the {0} to help you complete the configuration, then save it.',
        linkText: 'Azure workers documentation',
        paragraph: {
          workersDeployed: 'Workers are deployed on Azure through Azure Resource Manager.',
          deployToAzure: 'Click on the "Deploy to Azure" button to be redirected to your Azure Console with a pre-filled custom deployment configuration.',
          copyPaste: 'Copy paste the values below in the corresponding fields',
          serverConfig: 'In addition you\'ll need to know these Cycloid server configuration values:',
          help: 'If you need further help, contact your system administrator.',
        },
      },
      es: {
        checkDoc: 'Verifique la {0} para ayudarlo a completar la configuración, luego guárdelo.',
        linkText: 'documentación de los workers de Azure',
        paragraph: {
          workersDeployed: 'Los workers se despliegan en Azure a través de Azure Resource Manager.',
          deployToAzure: 'Haga clic en el botón "Desplegar en Azure" para ser redirigido a su consola de Azure con una configuración de despliegue personalizada y pre-completada.',
          copyPaste: 'Copiar Pegar los valores a continuación en los campos correspondientes',
          serverConfig: 'Además, necesitará conocer estos valores de configuración del servidor Cycloid:',
          help: 'Si necesita más ayuda, póngase en contacto con el administrador del sistema.',
        },
      },
      fr: {
        checkDoc: 'Vérifiez la {0} pour vous aider à compléter la configuration, puis enregistrez-la.',
        linkText: 'documentation des workers Azure',
        paragraph: {
          workersDeployed: 'Les workers sont déployés sur Azure via Azure Resource Manager.',
          deployToAzure: 'Cliquez sur le bouton "Déployer vers Azure" pour être redirigé vers votre console Azure avec une configuration de déploiement personnalisée pré-remplie.',
          copyPaste: 'Copier coller les valeurs ci-dessous dans les champs correspondants',
          serverConfig: 'De plus, vous devrez connaitre ces valeurs de configuration de serveur Cycloid:',
          help: `Si vous avez besoin d'aide supplémentaire, contactez votre administrateur système.`,
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.add-azure {
  &__list--nested {
    list-style: disc;
  }
}
</style>
