<template>
  <div>
    <CyWorkersAddTitle icon-type="aws">
      {{ $t('untranslated.aws.name') }}
    </CyWorkersAddTitle>

    <p>{{ $t('p1') }}</p>

    <ul>
      <li>{{ $t('l1') }}</li>
      <li>{{ $t('l2') }}</li>
      <li>
        <i18n path="checkDoc">
          <a
            :href="$docLinks.workers.aws"
            class="cy-link"
            target="_blank">{{ $t('linkText') }}</a>
        </i18n>
      </li>
    </ul>

    <v-select
      :items="$static.AWS_CLOUD_WATCH_REGIONS"
      :label="$t('region')"
      :hint="$t('regionHint')"
      persistent-hint
      required
      class="required-field mt-5"
      @change="(cloudWatchRegion) => $emit('update', { cloudWatchRegion })"/>

    <p class="mt-6 mb-0">
      <i18n path="workers.troubleshoot">
        <a
          :href="$docLinks.workers.troubleshooting"
          class="cy-link"
          target="_blank">{{ $t('workers.troubleshootLink') }}</a>
      </i18n>
    </p>
  </div>
</template>

<script>
import AWS_CLOUD_WATCH_REGIONS from '@/utils/config/aws-cloudwatch-regions'
import CyWorkersAddTitle from '@/components/workers/add-title.vue'

export default {
  name: 'CyWorkersAddAwsStepTwo',
  components: {
    CyWorkersAddTitle,
  },
  props: {
    // ! needed for add.vue canSelect method
    /* eslint-disable vue/no-unused-properties */
    teamId: {
      type: String,
      required: true,
    },
    workerKey: {
      type: String,
      required: true,
    },
    /* eslint-enable vue/no-unused-properties */
  },
  computed: {
    $static: () => ({
      AWS_CLOUD_WATCH_REGIONS,
    }),
  },
  i18n: {
    messages: {
      en: {
        checkDoc: 'Check the {0} to help you complete the configuration, then save it.',
        l1: 'Select your Amazon region below',
        l2: 'Clicking on the "Deploy to AWS" button will redirect you to the Amazon Console, with a pre-filled CloudFormation stack configuration',
        linkText: 'AWS workers documentation',
        p1: 'Workers are deployed on AWS through AWS CloudFormation.',
        region: 'Amazon Region',
        regionHint: 'This is the region where you will launch the stack',
      },
      es: {
        checkDoc: 'Verifique la {0} para ayudarlo a completar la configuración, luego guárdelo.',
        l1: 'Seleccione la región de Amazon a continuación',
        l2: 'Cliquer sur le bouton "Déployer sur AWS" vous redirigera sur la console Amazon, avec une configuration de piles cloudFormation pré-remplies.',
        linkText: 'documentación de los workers de AWS',
        p1: 'Los workers se despliegan en AWS a través de AWS CloudFormation.',
        region: 'Region Amazon',
        regionHint: 'Este campo se utiliza para seleccionar la región en la que se lanzará el stack',
      },
      fr: {
        checkDoc: 'Vérifiez la {0} pour vous aider à compléter la configuration, puis enregistrez-la.',
        l1: 'Sélectionnez votre région Amazon ci-dessous',
        l2: 'Cliquer sur le bouton "Déployer sur AWS" vous redirigera sur la console Amazon, avec une configuration de piles cloudFormation pré-remplies.',
        linkText: 'documentation des workers AWS',
        p1: 'Les workers sont déployés sur AWS via AWS Cloudformation.',
        region: 'Amazon Région',
        regionHint: 'Ce champ permet de sélectionner la région dans laquelle la stack sera lancée',
      },
    },
  },
}
</script>
