<template>
  <div>
    <h2 class="h5 add-docker__title mb-6">
      {{ $t('untranslated.docker') }}
      <CyTag
        class="ml-2"
        small
        variant="default">
        {{ $t('development') }}
      </CyTag>
    </h2>

    <div class="add-docker__notification my-6">
      <CyNotification
        theme="warning"
        :title="$t('warningTitle')"
        :content="$t('warningContent')"/>
    </div>

    <p>{{ $t('p1') }}</p>
    <p>{{ $t('p2') }}</p>

    <CyWorkersCodeEditor :code="code"/>

    <i18n path="checkDoc">
      <a
        :href="$docLinks.workers.docker"
        class="cy-link"
        target="_blank">{{ $t('linkText') }}</a>
    </i18n>
    <i18n path="workers.troubleshoot">
      <a
        :href="$docLinks.workers.troubleshooting"
        class="cy-link"
        target="_blank">{{ $t('workers.troubleshootLink') }}</a>
    </i18n>
  </div>
</template>

<script>
import CyWorkersCodeEditor from '@/components/workers/code-editor'
import { mapGetters } from 'vuex'
import { generateDockerCode, generateDockerCodeOnPrem } from '@/utils/config/workers'

export default {
  name: 'CyWorkersAddDockerStepTwo',
  components: {
    CyWorkersCodeEditor,
  },
  props: {
    teamId: {
      type: String,
      required: true,
    },
    workerKey: {
      type: String,
      required: true,
    },
    schedulerHost: {
      type: String,
      default: '',
    },
    schedulerPort: {
      type: String,
      default: '',
    },
    schedulerPublicTSAKey: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('organization/licence', [
      'isOnPrem',
    ]),
    code () {
      const { teamId, workerKey, schedulerHost, schedulerPort, schedulerPublicTSAKey } = this

      return this.isOnPrem
        ? generateDockerCodeOnPrem({
          teamId,
          workerKey,
          schedulerHost,
          schedulerPort,
          schedulerPublicTSAKey,
        })
        : generateDockerCode({ teamId, workerKey })
    },
  },
  i18n: {
    messages: {
      en: {
        checkDoc: 'Check the {0} to get more information. ',
        linkText: '@:untranslated.docker workers documentation',
        p1: 'A @:untranslated.docker version with cgroup v1 support is required. If your installation does not meet the requirements, use a @:untranslated.bareMetal worker instead.',
        p2: 'Run the command below to deploy your worker.',
        warningTitle: 'For development purposes only.',
        warningContent: 'Running workers using @:untranslated.docker is not suitable for production due to its poor performance. Use cloud-hosted or @:untranslated.bareMetal workers instead.',
      },
      es: {
        checkDoc: 'Consulte la {0} para obtener más información. ',
        linkText: 'documentación de los workers de @:untranslated.docker',
        p1: 'Se requiere una versión de @:untranslated.docker compatible con cgroup v1. Si su instalación no cumple con los requisitos, utilice un worker de @:untranslated.bareMetal en su lugar.',
        p2: 'Ejecute el comando a continuación para desplegar su worker.',
        warningTitle: 'Solo con fines de desarrollo.',
        warningContent: 'La ejecución de workers con @:untranslated.docker no es adecuada para producción debido a su bajo rendimiento. Utilice workers alojados en la nube o @:untranslated.bareMetal en su lugar.',
      },
      fr: {
        checkDoc: `Vérifiez la {0} pour obtenir plus d'informations. `,
        linkText: 'documentation des workers @:untranslated.docker',
        p1: 'Une version de @:untranslated.docker avec prise en charge de cgroup v1 est requise. Si votre installation ne répond pas aux exigences, utilisez plutôt un worker @:untranslated.bareMetal .',
        p2: 'Exécutez la commande ci-dessous pour déployer votre worker.',
        warningTitle: 'À des fins de développement uniquement.',
        warningContent: `Utiliser des workers à l'aide de @:untranslated.docker n'est pas adapté à un environement de production en raison de leurs performances limitées. Utilisez à la place des workers hébergés dans le cloud ou @:untranslated.bareMetal .`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.add-docker {
  &__title {
    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;
  }
}
</style>
