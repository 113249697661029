<template>
  <div class="summary-item">
    <v-text-field
      :value="value"
      label="Regular"
      hide-details
      dense
      readonly
      disabled>
      <div
        slot="label"
        class="summary-item__label text-body-1">
        {{ text }}
      </div>
      <CyCopyBtn
        v-if="withCopy"
        :copy-hint="text"
        :copy-value="value"
        @click.stop
        slot="append"/>
    </v-text-field>
  </div>
</template>

<script>
import CyCopyBtn from '@/components/copy-btn'

export default {
  name: 'CyWorkersSummaryItem',
  components: {
    CyCopyBtn,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    withCopy: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-item {
  display: flex;
  margin: 1rem 0;

  &:not(:last-child) {
    margin-bottom: 1.75rem;
  }

  &__label {
    color: get-color("grey", "dark-1");
  }
}

::v-deep .v-text-field {
  input {
    color: get-color("grey", "dark-2") !important;
    font-size: map.get($font-sizes, "sm") !important;
  }

  .v-input__slot::before {
    border-image:
      repeating-linear-gradient(to right, get-color("black", $alpha: 0.4) 0, get-color("black", $alpha: 0.4) 2px, transparent 2px, transparent 4px)
      1
      1
      0
      repeat;
  }
}
</style>
